import React from 'react';
import Icon from 'components/Icon';
import { AuthenticationLayout } from 'components/Layout/AuthenticationLayout';
import { LoginForm } from 'components/Authentication/Forms';
import styles from './Login.module.css';
import { PublicLayout } from '../Layout/PublicLayout';

export function Login() {
  return (
    <PublicLayout
      header={() => (
        <div className="l-flex column align-center">
          <img alt="login icon" className="m-b--large page-icon" src="/images/icon-login.svg" />
          <h1 className="h-color--white m-b--none">Login</h1>
        </div>
      )}
    >
      <h1 className="h-color--navy">Login to your account</h1>
      <LoginForm />
    </PublicLayout>
  );
}
